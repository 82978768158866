<template>
  <v-img
    :src="imageUrl"
    :lazy-src="lazySrc"
    v-bind="$attrs"
    contain
    style="height: 100vh; width: 100vw"
  >
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          :size="70"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>
<script>
export default {
  name: 'GalleryImage',
  props: { gallery: String, image: String, lazySrc: String },
  data() {
    return {
      imageUrl: '',
      loading: false,
    };
  },
  methods: {
    async fetchImage() {
      this.loading = true;
      const url = await this.apiBlob({
        path: `gallery/image?gallery=${this.gallery}&image=${this.image}`,
      });
      this.loading = false;
      if (url) {
        this.imageUrl = url;
      }
    },
  },
  async created() {
    this.fetchImage();
  },
};
</script>
